<template>
  <div class="home">
    <Header :active=4 :full='fullWidth'/>
    <!-- 轮播图 -->
    <el-image style="width: 100%; margin-top:74px" :src="imgs[0].url" v-if="fullWidth"></el-image>
    <el-image style="width: 100%; margin-top:52px" :src="imgs[1].url"  v-if="!fullWidth"></el-image>
    <!-- <el-carousel :height="fullWidth == true ? '400px' : '150px'">
      <el-carousel-item v-for="item in imgs" :key="item.id" >
        <el-image :src="item.url" style="width: 100%;height:100%;"></el-image>
      </el-carousel-item>
    </el-carousel> -->
    <!-- PC端 -->
    <div class="case_center" v-show="fullWidth">
      <div class="center_top">
        <span class="case_span">Successful cases</span>
        <span class="span_centet">成功案列</span>
        <span class="span_botton"></span>
      </div>
      <!-- 成功案列 -->
      <div class="case_success">
          <div  class="pc_suceess" v-for="(item,index) in list" :key="index">
              <div class="success" @click="goDetails(index)">
                   <img class="success_img" :src="item.url" alt="">
              </div>
              <!-- <div class="case_span1">防伪验证</div>
              <div class="case_text">验证产品真伪</div> -->
          </div>
      </div>
      <!-- 分页 -->
    <div class="paging">
        <div class="choice_left">

        </div>
        <span class="pagin_num" v-for="(item,index) in 11" :key="index">{{item}}</span>
        <div class="choice_right">

        </div>
    </div>
    </div>



    <!-- 移动端 -->
   <div class="move_center" v-show="!fullWidth">
      <div class="movecenter_top">
        <span class="moveme_span">Successful cases</span>
        <span class="movespan_centet">成功案列</span>
        <span class="movespan_botton"></span>
      </div>
      <!-- 成功案列 -->
      <div class="movecase_success">
          <div  class="movepc_suceess" v-for="(item,index) in list" :key="index">
              <div class="movesuccess" @click="goDetails(index)">
                   <img class="movesuccess_img" :src="item.url" alt="">
              </div>
              <!-- <div class="movecase_span1">防伪验证</div>
              <div class="movecase_text">验证产品真伪</div> -->
          </div>
      </div>

    </div>
    <Footer :full='fullWidth'/>
  </div>

</template>

<script>
// @ is an alias to /src

import Header from "@/components/Header.vue";
import Footer from '@/components/Footer.vue'
export default {
  name: "Home",
  components: {
      Header,
      Footer
    },

  data(){
    return{
      // 轮播图
      imgs:[
        {id:1,url:require('../../assets/img/case.jpeg')},
        {id:2,url:require('../../assets/img/banner/move_case.png')},
      ],
      list:[
        {url:require('../../assets/shouye/img1.png')},
        {url:require('../../assets/shouye/img.png')},
        {url:require('../../assets/shouye/img2.png')},
        {url:require('../../assets/shouye/img3.png')},
      ],
      fullWidth:true,
    }
  },
  created(){

  },
  methods:{
      page_width() {//获取屏幕宽度
      let screenWidth = window.screen.width;
      console.log(screenWidth);
      if (screenWidth < 800) {
        this.fullWidth = false;
      } else {
        this.fullWidth = true;
      }

    },
    setRem() {
      var whdef = 100/1920;// 表示1920的设计图,使用100PX的默认值
      var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
      var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
    },
    goDetails(index){
        if(index==0||index===3){
            this.$router.push({path:'/case/details?id='+index})
        }
    }
  },
  mounted(){
    window.onresize = () => {//监听屏幕变化
      this.page_width();
      this.setRem()
    };
    this.page_width();
    this.setRem()
  },

};
</script>
<style scoped>

/* PC端样式 */
/* 头 */
.case_center{display: flex;flex-direction:column;width: 1024px;margin: 60px auto 0;align-items: center}
.center_top{margin: 0 auto;display: flex;flex-direction:column;align-items: center;}
.case_span{/* width: 377px; */ height: 25px;font-size: 18px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #E02222;line-height: 25px;}
.span_centet{height: 25px;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #474747;line-height: 25px;margin: 11px 0 10px 0;}
.span_botton{width: 79px;height: 8px;background: #DDDDDD;border-radius: 4px;}

/* 成功案列 */
.case_success{display: flex;margin-top: 39px;justify-content: center;flex-wrap: wrap;}
.pc_suceess{display: flex;flex-direction: column;align-items: center;cursor: pointer}
.success{width: 246px;height: 246px;border-radius: 10px;margin-right: 10px;}
.success_img{width: 100%;height: 100%;border-radius: 10px;}
.case_span1{margin-top: 22px; text-align: center;width: 64px;height: 22px; font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #000000;line-height: 22px;}
.case_text{margin-top: 6px; width: 84px;height: 20px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #252525;line-height: 20px;}
.paging{width:800px; margin-bottom: 78px;   align-items: center; display: flex;margin-top: 100px;justify-content: center;flex-wrap: wrap;}
.pagin_num{text-align: center; width: 32px;height: 32px;border: 1px solid #979797;margin: 0 7px;height: 37px;font-size: 26px;font-family: PingFangSC-Light, PingFang SC;font-weight: 300;color: #727272;line-height: 37px;}
.choice_left{margin-right: 10px; width: 32px;height: 32px; background-image:url(../../assets/img/zuo.png);background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.choice_right{margin-left: 10px;  width: 32px;height: 32px; background-image:url(../../assets/img/you.png);background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
/* 移动端样式 */
.move_center{margin-top: 20px;display: flex;flex-direction:column;flex-wrap: wrap;}
.movecenter_top{margin: 0 auto;display: flex;flex-direction:column;align-items: center;}
.moveme_span{height: 17px;font-size: 12px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #E02222;line-height: 17px;}
.movespan_centet{margin-top: 5px;height: 17px;font-size: 12px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #474747;line-height: 17px;}
.movespan_botton{width: 40px;height: 4px;background: #DDDDDD;border-radius: 4px;margin-top: 5px;}

/* 成功案列 */
.movecase_success{display: flex;margin-top: 22px;justify-content: center;flex-wrap: wrap;margin-bottom: 103px;}
.movepc_suceess{display: flex;flex-direction: column;align-items: center;}
.movesuccess{width: 83px;height: 83px;border-radius: 5px;margin-right: 10px;}
.movesuccess_img{width: 100%;height: 100%;border-radius: 5px;}
.movecase_span1{text-align: center;margin-top: 10px; width: 64px;height: 17px;font-size: 12px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #000000;line-height: 17px;}
.movecase_text{margin-top: 5px; margin-bottom: 10px; width: 42px;height: 9px;font-size: 7px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #252525;line-height: 9px;}
</style>
